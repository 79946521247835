import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import SEO from "../components/seo"
import _ from "lodash"
import moment from "moment"
import { useQueryParam, StringParam } from "use-query-params"
import marked from "marked"
import slugify from "slugify"
import WebriqForm from "../components/form/form"

const Blogs = ({ data }) => {
  // console.log(data)
  const [date] = useQueryParam("date", StringParam)
  const posts = date
    ? data.allSanityPost.edges.filter(f => f.node.date === date)
    : data.allSanityPost.edges
  const postLength = posts.length
  const filterMonthYear = _.groupBy(data.allSanityPost.edges, b =>
    moment(b.node.publishedAt)
      .startOf("month")
      .format("MMM YYYY")
  )
  // console.log("filterMonthYear", Object.entries(filterMonthYear))
  // console.log("datedate", date)

  return (
    <Layout page="news">
      <SEO
        title="Latest News"
        description={data.site.siteMetadata.description}
      />
      <div className="page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h1>News</h1>
          </div>
        </div>
      </div>
      <div className="blog-section">
        <Container>
          <Row className="justify-content-center">
            {posts.length !== 0 &&
              posts.map((blog, index) => (
                <Col
                  sm={6}
                  md={
                    (posts.length >= 2 && index === 0) ||
                    (posts.length >= 2 && index === 1) ||
                    (posts.length >= 7 && index === postLength - 1) ||
                    (posts.length >= 7 && index === postLength - 2)
                      ? 6
                      : 4
                  }
                  lg={
                    (posts.length >= 2 && index === 0) ||
                    (posts.length >= 2 && index === 1) ||
                    (posts.length >= 7 && index === postLength - 1) ||
                    (posts.length >= 7 && index === postLength - 2)
                      ? 6
                      : 4
                  }
                  key={index}
                  className={
                    (posts.length >= 2 && index === 0) ||
                    (posts.length >= 2 && index === 1)
                      ? "mb-5 pb-100"
                      : "mb-5"
                  }
                >
                  <Link to={`/${blog?.node?.slug?.current}`}>
                    <div
                      className="blog-item"
                      style={{
                        marginRight:
                          (posts.length > 2 && index === 0 && 0) ||
                          (posts.length >= 7 && index === postLength - 2 && 30),
                        marginLeft:
                          (posts.length > 2 && index === 1 && 0) ||
                          (posts.length >= 7 && index === postLength - 1 && 30),
                      }}
                    >
                      <div
                        className="bg-blog position-relative"
                        style={{
                          backgroundImage: `url(${blog.node?.mainImage?.asset?.fluid?.src})`,
                        }}
                      >
                        <div className="blog-date">
                          <p>
                            <i
                              className="fa fa-calendar-o"
                              aria-hidden="true"
                            ></i>{" "}
                            {blog.node.publishedAt}
                          </p>
                        </div>
                      </div>
                      <div className="blog-body">
                        <h3>{blog.node.title}</h3>
                        <div
                          className="blog-excerpt mb-4"
                          dangerouslySetInnerHTML={{
                            __html: marked(blog.node.excerpt || ""),
                          }}
                        />
                        <div className="blog-foot">
                          <p className="m-0">
                            <Link
                              to={`/${blog?.node?.slug?.current}`}
                              style={{ color: "#00C1C8" }}
                            >
                              Read More{" "}
                              <i
                                className="fa fa-arrow-right"
                                ariaHidden="true"
                              ></i>
                            </Link>
                          </p>
                          {/* <Row> */}
                          {/*
                            <Col xs={7} sm={6}>
                              {blog.node.authors.length !== 0 && (
                                <p className="d-sm-none d-lg-block">
                                  <i
                                    className="fa fa-user"
                                    ariaHidden="true"
                                    style={{ color: "#00C1C8" }}
                                  ></i>{" "}
                                  <span className="">
                                    {blog.node.authors[0].person.name}
                                  </span>
                                </p>
                              )}
                            </Col>
                            
                            <Col xs={5} sm={6} className="text-right">
                              
                            </Col>
                            */}
                          {/* </Row> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
          </Row>

          <Row className="mt-5">
            <Col lg={5}>
              <div className="recent pl-lg-5 pr-lg-5">
                <h4>Recent</h4>
                <ul className="list-unstyled">
                  {data.allSanityPost.edges.map(post => (
                    <li key={post.node.id}>
                      <Link to={`/${post.node?.slug?.current}`}>
                        {post.node.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col lg={6} className="offset-lg-1">
              <div className="subs-blog text-center">
                <h3 className="text-white position-relative pb-5">
                  Subscribe to the blog
                </h3>
                <p>
                  Enter your email to subscribe to this blog and receive
                  notifications of new posts by email.
                </p>
                <WebriqForm
                  form_id="7050eedd-8d8f-4ed8-b5de-f00c71644cc8"
                  name="Subscribe Blog"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="text-center w-100"
                      placeholder="Email*"
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="name"
                      name="name"
                      className="text-center w-100"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn mt-4">
                      Subscribe
                    </button>
                  </div>
                </WebriqForm>
              </div>
              <div className="arc-cat mt-5">
                <Row>
                  <Col lg={3}>
                    <h4>Archives</h4>
                    <ul className="list-unstyled">
                      {Object.entries(filterMonthYear).map((date, index) => (
                        <li key={index}>
                          <Link to={`/news?date=${date[0]}`}>{date[0]}</Link>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col lg={9}>
                    <h4>Categories</h4>

                    <ul className="list-unstyled d-flex flex-wrap">
                      {data.allSanityPost.group.map((cat, index) => (
                        <li key={index} className="mr-2">
                          <Link
                            to={`/category/${slugify(
                              cat.fieldValue.toLowerCase()
                            )}`}
                          >
                            {cat.fieldValue}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMM-DD-YYYY")
          date: publishedAt(formatString: "MMM YYYY")
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          excerpt
          slug {
            current
          }
          authors {
            person {
              name
            }
          }
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
